<template>
    <div class="player">
        <video-player
            ref="videoPlayer"
            class="vjs-custom-skin"
            :options="playerOptions"
            @play="onPlayerPlay($event)"
            @ready="onPlayerReady($event)"
        >
        </video-player>
    </div>
</template>

<script>
export default {
    props: ['url'],
    data() {
        return {
            playerOptions: {
                autoplay: true,
                controls: true,
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true
                }
            }
        }
    },
    computed: {
        player() {
            return this.$refs.videoPlayer.player
        }
    },
    mounted() {
        this.playVideo(this.url)
    },
    methods: {
        onPlayerPlay(player) {
            console.log('player play!', player)
        },
        onPlayerReady(player) {
            console.log('player ready!', player)
            this.player.play()
        },
        playVideo(source) {
            const video = {
                withCredentials: false,
                type: 'application/x-mpegurl',
                src: source
            }
            this.player.reset() // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
            this.player.src(video)
            // this.player.load()
            this.player.play()
        }
    }
}
</script>

<style scoped>
.player {
    @apply overflow-hidden;
    position: absolute !important;
    width: 100%;
    height: 100%;
}
.vjs-custom-skin {
    height: 100% !important;
    width: 100% !important;
}

.vjs-custom-skin /deep/ .video-js {
    height: 100%;
    width: 100%;
}

</style>
