/* eslint-disable */
import axios from 'axios'
const Connect = {
    data() {
        return {
            host: undefined,
            video_id: undefined,
            hls: undefined,

            tests: 5
        }
    },
    methods: {
        connect_start: function (host, roomId) {
            this.host = host
            this.video_id = undefined
            this.hls = undefined

            this.tests = 5

            this.initSocketVideo(roomId)
        },
        initSocketVideo: function (id) {
            const socket = io.connect(`${this.host}`, {query: `room=${id}`, secure: true});

            socket.on('welcome', (data) => {
                if (data.segment) {
                    if (data.segment === this.video_id) {
                        // faz nada, está rodando o video
                    } else {
                        this.hls = undefined

                        this.video_id = data.segment
                        this.tests = 5
                        this.waitForAvailable(id)
                    }
                }
            })

            socket.on('status', data => {
                if (data.status === 'R:RE') {
                    setTimeout(() => socket.connect(), 3000)
                } else {
                    this.erro_mensagem = ''
                    this.online = false
                }
            })

            socket.on('disconnect', function(ex) {

            })
        },
        startVideo: function () {
            const video = this.$refs.video_player
            const videoSrc = this.getUrl;

            if (Hls.isSupported()) {
                this.hls = new Hls({
                    debug: false,
                    lowLatencyMode: true,
                    enableWorker: true
                });
                this.hls.loadSource(videoSrc);
                this.hls.attachMedia(video);
            } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.src = videoSrc
            }
        },
        waitForAvailable: function (id) {
            const params = new URLSearchParams({channel: id})

            axios.post(`${this.host}/is_ready`, params.toString())
                .then(({data}) => {
                    if (data.status) {
                        this.startVideo()
                    } else {
                        if (this.tests > 0) {
                            this.tests--;
                            setTimeout(() => this.waitForAvailable(id), 2000)
                        }
                    }
                })
                .catch(e => {
                    this.online = false
                    this.erro_mensagem = 'Ocorreu erro ao verificar disponibilidade da transmissão'
                })
        }
    },
    computed: {
        getUrl: function () {
            return `${this.host}/segments/stream_${this.video_id}.m3u8`
        }
    }
}
export default Connect