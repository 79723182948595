import axios from 'axios'

const api = axios.create({
    baseURL: 'https://api.mercurio.marketing',
    headers: {
        Accept: 'application/json'
    }
})

const setJwtInterceptor = (token) => {
    api.interceptors.request.use(function (config) {
        config.headers.Authorization = `Bearer ${token}`
        return config
    })
}

export default api
export { setJwtInterceptor }
