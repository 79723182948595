<template>
  <header>
    <div class="navbar">
      <div class="navbar--header">
        <div :key="social.id" v-for="social in socials" class="navbar--header--item">
          <div class="navbar--header--item__icons">
            <a :href="social.link" target="_blank">
              <img v-for="icon in social.icons" :key="icon" :src="icon" />
            </a>
          </div>
          <div class="navbar--header--item__label" v-html="social.label"></div>
        </div>
      </div>
      <div class="navbar--menu">
        <composed-div @click.native="open()" class="navbar--menu__trigger">
          <img src="/general/navbar/icons/menu-trigger.png" alt="Menu" />
        </composed-div>
        <div :class="{ 'navbar--menu__wrapper': true, active: menu }">
          <div @click="close()" class="navbar--menu__wrapper--close">
            <font-awesome-icon icon="xmark" />
          </div>
          <div class="navbar--menu__wrapper--list">
            <router-link :to="$t('routes.' + section)" class="navbar--menu__wrapper--list__item"
              v-for="section in sections" :key="section" v-html="$t('navbar.' + section)" />
          </div>
        </div>
      </div>
      <composed-div class="navbar--section">
        {{ routeName }}
      </composed-div>
      <router-link to="/" class="navbar--logo">
        <img src="/general/navbar/logo.png" alt="Logo" />
      </router-link>
      <div class="navbar--wrapper">
        <div class="navbar--wrapper__socials">
          <a :href="social.link" target="_blank" :key="social.id" v-for="social in socials" class="navbar--wrapper__socials--item">
            <div class="navbar--wrapper__socials--item__icons">
                <img v-for="icon in social.icons" :key="icon" :src="icon" />
            </div>
            <div class="navbar--wrapper__socials--item__label" v-html="social.label"></div>
          </a>
        </div>
        <div class="navbar--wrapper__container">
          <router-link :to="$t('routes.' + section)" class="navbar--wrapper__container--item" v-for="section in sections"
            :key="section" v-html="$t('navbar.' + section)" />
        </div>
      </div>
    </div>
    <badge v-if="routeName == 'Home'" :badgeImage="'/general/home/diamond.png'" :badgeTop="'8rem'" :badgeText="$t('badge.diamante.text')" :badgeLink="$t('badge.diamante.link')"/>
		<badge v-if="routeName == 'Home'" :badgeImage="'/general/home/gold.png'" :badgeTop="'12rem'" :badgeText="$t('badge.ouro.text')" :badgeLink="$t('badge.ouro.link')"/>
		<badge v-if="routeName == 'Home'" :badgeImage="'/general/home/silver.png'" :badgeTop="'16rem'" :badgeText="$t('badge.prata.text')" :badgeLink="$t('badge.prata.link')"/>
    <home-frame v-if="routeName == 'Home'"/>
  </header>
</template>

<script>
import store from '@/store/index.js'
export default {
  name: 'CustomMenu',
  data() {
    return {
      store,
      menu: false,
      sections: ['home', 'nossa_historia', 'nossos_planos', 'a_funeraria', 'velorio_online', 'obituario', 'floricultura'],
      socials: [
      {
          id: 1,
          label: '<span>(18)</span> 3271-1500',
          icons: ['/general/navbar/icons/phone.png'],
          link: 'tel:+551832711500'
        },
        {
          id: 2,
          label: '<span>(18)</span> 99628-1500',
          icons: ['/general/navbar/icons/wpp.png'],
          link: 'https://wa.me//5518996281500'
        },
        {
          id: 3,
          label: '',
          icons: ['/general/navbar/icons/facebook.png'],
          link: 'https://www.facebook.com/OrganizacaoPresidente'
        },
        {
          id: 4,
          label: '@organizacaopresidente',
          icons: ['/general/navbar/icons/instagram.png'],
          link: 'https://www.instagram.com/organizacaopresidente/'
        }
      ]
    }
  },
  methods: {
    open() {
      this.menu = true
    },
    close() {
      this.menu = false
    }
  },
  watch: {
    $route() {
      this.menu = false
    }
  },
  mounted() {
      const links = document.querySelectorAll('a[target="_blank"]')
      links.forEach((link) => {
          link.addEventListener('click', () => {
              const href = link.getAttribute('href')

              if (href && !href.includes(location.host)) {
                  this.store.dispatch('setAccessEvent', {
                      event: 'external_link',
                      value: href
                  })
              }
          })
      })
  },
  computed: {
    routeName() {
      let name = this.$route.name
      if (name === 'plano') {
        const label = this.$route.params.label
            const summary = {
                'diamante-mais': 'diamond',
                'ouro-mais': 'gold',
                'prata-mais': 'silver'
            }

            name = summary[label]
      }
      return this.$t('navbar.' + name)
    }
  },
}
</script>

<style lang="scss">
header {
  @apply z-50 relative;
  .navbar {
    @apply grid items-start gap-x-4;
    grid-template-columns: auto 1fr auto;
    z-index: 999;

    @screen lg {
      @apply gap-x-2 flex justify-between ;
      padding: 1.5rem 2.5% 20px 2.5%;
    }
    @screen xl {
      @apply gap-x-4;
      padding: 1.5rem 7.5% 20px 7.5%;
    }
    @screen xxl {
      @apply mx-auto;
      max-width: 1366px;
      padding: 1.5rem 5% 20px 5%;
    }

    &--header {
      grid-column: 1/4;
      @apply flex gap-x-2 pt-4 pb-6 justify-self-center;

      &--item {
        @apply flex gap-x-1 items-center;

        &:last-child {
          margin-left: -10px;
          .navbar--header--item__label{
            display: none;
          }
        }

        &__icons {
          @apply flex gap-x-1;

          img {
            height: 20px;
          }
        }

        &__label {
          @apply font-bold whitespace-nowrap;
          font-size: 16px;

          span {
            font-size: 10px;
          }
        }
      }

      @screen lg {
        @apply hidden;
      }
    }

    &--menu {
      &__trigger {
        width: 70px;

        .content {
          @apply px-5 py-3 text-white text-4xl relative z-20;
          width: 70px;

          // img {
          //   @apply h-full object-contain;
          // }

          border-top-right-radius: 24px;
          border-bottom-right-radius: 24px;

        }

      }

      &__wrapper {
        @apply grid items-center opacity-0 bg-blue fixed top-0 left-0 pointer-events-none transition-all duration-300;
        width: 100vw;
        height: 100vh;
        z-index: 999;

        &--close {
          @apply absolute top-0 left-0 p-6 text-white text-2xl;
        }

        &--list {
          @apply grid gap-y-4 justify-center items-center;

          &__item {
            @apply text-white text-center font-bold rounded-full px-3 py-2;

            &.router-link-exact-active {
              @apply bg-orange shadow-sm;
              text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
            }
          }
        }

        &.active {
          @apply opacity-100 pointer-events-auto;
        }

      }

      @screen lg {
        @apply hidden;
      }
    }

    &--section {
      @apply text-white font-bold text-lg whitespace-nowrap;

      .content {
        @apply px-6 py-3 rounded-3xl;
      }

      .background {
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
      }

      @screen lg {
        @apply hidden;
      }
    }

    &--logo {
      @screen lg {
        width: 180px;
      }

      img {
        @apply mr-4;
        width: 110px;
        margin-top: -1rem;

        @screen lg {
          @apply m-0 w-full
        }
      }
    }

    &--wrapper {
      @apply hidden gap-y-2 pt-2;

      @screen lg {
        @apply grid;
      }

      &__socials {
        @apply flex gap-x-5 justify-self-end pr-8;

        &--item {
          @apply flex gap-x-1 items-center;

            &:last-child {
              margin-left: -20px;
            }

          &__icons {
            @apply flex gap-x-1;

            img {
              height: 17px;
            }
          }

          &__label {
            @apply font-extrabold;
            font-size: 14px;

            span {
              font-size: 8px;
            }
          }
        }
      }

      &__container {
        @apply flex rounded-full gap-x-4 items-center px-8 py-2 relative;
        @screen lg {
          @apply gap-x-2;
        }
        @screen xl {
          @apply gap-x-4;
        }

        &--item {
          @apply text-white p-2 font-bold cursor-pointer whitespace-nowrap h-full transition-all duration-300 rounded-full z-30;
          @screen lg {
            @apply text-lg;
          }
          &.router-link-exact-active,
          &:hover {
            @apply bg-orange shadow-sm;
            text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
          }
        }

        &::before {
          @apply absolute bg-blue rounded-full z-20 shadow-sm;
          content: '';
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
        }

        &::after {
          @apply absolute bg-orange rounded-full z-10;
          content: '';
          left: -2px;
          right: -2px;
          bottom: -5px;
          height: 100%;
        }
      }
    }
  }
}</style>
