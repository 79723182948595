<template>
    <picture v-if="image">
        <source media="(max-width: 1023px)" :srcset="baseUrl + image.mobile">
        <img media="(min-width: 1024px)" :srcset="baseUrl + image.desktop">
    </picture>
</template>

<script>
import store from '@/store/index.js'
export default {
    props: {
        src: {
            required: true
        }
    },
    data() {
        return {
            store
        }
    },
    computed: {
        images() {
            return this.store.state.website_images
        },
        baseUrl() {
            return this.store.state.baseUrl
        },
        image() {
            const images = this.images

            return images[this.src]
        }
    }
}
</script>