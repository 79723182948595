<template>
    <div class="velorio--online">
        <!-- <Badge :badgeImage="'/general/velorio-online/flor.png'" :badgeTop="'12rem'" :badgeWidthMax="'350px'" :badgeText="$t('badge.flor.text')" :badgeLink="$t('link.floricultura')" :marginImage="'-55px'"/> -->
        <div class="velorio--online__background">
            <dynamic-image src="/velorio-online/background.png" />
        </div>
        <div class="velorio--online__infos">
            <div class="velorio--online__infos--image">
                <responsive-image src="/velorio-online/image.png" />
            </div>
            <div class="velorio--online__infos--description" @click="introRunning = !introRunning" v-html="$t('velorio_online.description')" />
        </div>
        <div class="velorio--online__transmission">
            <div class="velorio--online__transmission--form">
                <div class="velorio--online__transmission--form__field">
                    <div class="velorio--online__transmission--form__field--label">
                        {{ $t('velorio_online.login') }}:
                    </div>
                    <div class="velorio--online__transmission--form__field--input">
                        <input ref="loginInput" type="text" v-model="login" />
                    </div>
                </div>
                <div class="velorio--online__transmission--form__field">
                    <div class="velorio--online__transmission--form__field--label">
                        {{ $t('velorio_online.password') }}:
                    </div>
                    <div class="velorio--online__transmission--form__field--input">
                        <input type="password" v-model="password" />
                    </div>
                </div>
                <div class="velorio--online__transmission--form__field">
                    <button @click="join()">
                        <transition name="fade">
                            <span  v-if="loading">
                                <font-awesome-icon icon="spinner" spin />
                            </span>
                            <span v-else>{{ $t('velorio_online.join') }}</span>
                        </transition>
                    </button>
                </div>
            </div>
            <div class="velorio--online__transmission--video">
                <transition name="fade" mode="out-in">
                    <div v-if="login_attempt === 0" class="velorio--online__transmission--video__wait">
                        <div class="velorio--online__transmission--video__wait--row">
                            <transition name="fade" mode="out-in">
                                <span class="failed" v-if="failedLogin">
                                    <font-awesome-icon  icon="circle-exclamation" />{{ $t('velorio_online.failedLogin') }}
                                </span>
                                <span class="waiting" v-else>
                                    <font-awesome-icon  icon="circle-question" />{{ $t('velorio_online.makeLogin') }}
                                </span>
                            </transition>
                        </div>
                        <div class="velorio--online__transmission--video__wait--row">
                            <transition name="fade" mode="out-in">
                                <font-awesome-icon class="denied" v-if="failedLogin" icon="ban" />
                                <font-awesome-icon v-else @click="focus()" icon="play" />
                            </transition>

                        </div>
                    </div>
                    <div v-else class="velorio--online__transmission--video__player">
                        <transition name="fade" mode="out-in">
                            <video ref="videoRef" @ended="videoEnded" key="video" v-if="introRunning"
                                :src="baseUrl + documents.intro_velorio_online" controls></video>
                            <m3u8-player key="m3u8" v-else
                                :url="cameras[0].url" />
                        </transition>
                    </div>
                </transition>
            </div>
            <div class="velorio--online__transmission--action">
                <div class="velorio--online__transmission--action__container" v-html="$t('obituario.description')" />
                <div class="velorio--online__transmission--action__button">
                    <a :href="$t('link.floricultura')" target="_blank">
                        {{ $t('obituario.button') }} >
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import store from '@/store/index.js'
// import Badge from '../components/Badge.vue'
export default {
    name: 'VelorioOnline',
    data() {
        return {
            store,
            login_attempt: 1,
            introRunning: true,
            login: '',
            password: '',
            cameras: [],
            failedLogin: false,
            loading: false,
            documents: {}
        }
    },
    computed: {
        baseUrl() {
            return this.store.state.baseUrl
        }
    },
    created() {
        this.store.dispatch('getWebsiteDocuments').then(() => {
            this.documents = this.store.state.website_documents
        })
    },
    // components:{
    //     Badge
    // },
    mounted() {
        setTimeout(() => {
            this.store.commit('setLoading', false)
        }, 300)

        this.store.dispatch('setAccessEvent', {
            event: 'page_enter',
            value: 'velorio-online'
        })
    },
    methods: {
        fixVideoRatio() {
            const wrapper = document.querySelector('.velorio--online__transmission--video')
            const wrapperWidth = wrapper.offsetWidth;
            wrapper.style.height = `${wrapperWidth * 0.5625}px`;

            wrapper.querySelector('video').controls = true
        },
        focus(){
            this.$refs.loginInput.focus()
        },
        join() {
            if (this.login && this.password) {
                this.loading = true
            this.$axios.post('/op-transmission', {
                login: this.login,
                password: this.password
            }).then((response) => {
                this.loading = false
                this.cameras = response.data.cameras
                if (response.data.status) {
                    this.login_attempt += 1
                    this.$nextTick(() => {
                        this.playVideo()
                    })
                } else {
                    this.login = ''
                    this.password = ''
                    this.failedLogin = true
                    this.focus()
                }
            })
        }
        },
        playVideo() {
            const video = this.$refs.videoRef;

            if (video && (video.paused || video.ended)) {
                video.play();
                this.$nextTick(() => {
                    this.fixVideoRatio()  
                })
            }
        },
        videoEnded() {
            this.introRunning = false
        }
    },
    metaInfo: {
		title: 'Velório Online | Organização Presidente',
		meta: [
			{ name: 'description', content: '' },
			{ name: 'keywords', content: 'Organização Presidente, Funerária, Funerária Presidente Venceslau' },
		]
	},
}
</script>
  
<style lang="scss">
.vjs-control-bar {
    z-index: 99 !important;
}
video .vjs-tech {
    z-index: 0 !important;
}
.velorio--online {
    @apply grid p-4 pb-24 gap-y-8 items-start;
    @screen lg {
        grid-template-columns: 30% auto;
        padding: 1rem 2.5% 8rem 2.5%;
    }
    @screen xl {
        @apply gap-x-16;
        padding: 1rem 7% 8rem 7%;
    }

    &__background {
        @apply fixed left-0 top-0 w-full h-full;
        height: 100vh;
        width: 100vw;
        z-index: -1;

        img {
            @apply w-full h-full object-cover;
        }
    }

    &__infos {
        @apply pt-8;
        z-index: 10;
        @screen lg {
            @apply pt-0;
        }
        &--image {
            @apply flex justify-center pb-6;
        }

        &--description {
            @apply p-6 rounded-md text-blue font-medium;
            font-size: 13px;
            border: 2px solid #000;
            
            p:not(:last-child) {
                @apply pb-2;
            }

            text-shadow: 1.2px 1.2px 0.5px rgba(0, 0, 0, 0.2);
        }
    }

    &__transmission {
        @apply grid gap-y-8 h-full relative;
        grid-template-rows: auto 1fr;
        z-index: 10;
        &--form {
            @apply hidden gap-y-2 items-center py-6 px-8 bg-blue shadow-lg rounded-2xl;

            @screen lg {
                @apply flex opacity-0 rounded-full p-2 gap-x-2 justify-end justify-self-end pl-4;
            }
            @screen xl {
                @apply gap-x-5 pl-10;
                max-width: 750px;
            }

            &__field {
                @apply grid gap-x-3  gap-y-1 items-center;
                @screen lg {
                    @apply flex;
                }

                &--label {
                    @apply text-white font-semibold uppercase text-xs pl-4;
                    @screen lg {
                        @apply capitalize text-sm pl-0;
                    }
                    @screen xl {
                        @apply text-base
                    }
                }

                &--input {
                    input {
                        @apply rounded-full outline-none w-full;
                        padding: 6px 10px;
                        @screen lg {
                            width: unset;
                        }
                        margin-bottom: unset !important;
                    }
                }

                button {
                    @apply py-2 px-10 text-white font-bold rounded-full bg-orange w-full;
                    height: 40px;
                    @screen lg {
                        @apply text-sm px-0;
                        width: 80px;
                    }
                    @screen xl {
                        @apply text-base px-10;
                        width: 150px;
                    }
                }
                &:nth-child(3) {
                    @apply my-4;
                    @screen lg {
                        @apply my-0;
                    }
                }
            }
        }

        &--video {
            @apply relative transition-all duration-500 ease-in-out h-full;

            &__player {
                @apply h-full duration-500 ease-in-out;

                video,
                .player {
                    @apply w-full object-cover rounded-2xl z-50 relative transition-all duration-500 ease-in-out;
                }
            }

            &__wait {
                @apply grid pt-10 pb-20 gap-y-10 h-full w-full z-50 mx-auto rounded-2xl justify-center text-sm text-white relative shadow-md uppercase font-bold;
                @screen lg {
                    @apply py-10 gap-y-0;
                }
                text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
                background: #262538;
                left: -9px;
                top: 0.25rem;
                height: calc(100% - 2rem);
                width: calc(100% - 2rem);

                grid-template-rows: auto 1fr;
                &--row {
                    @apply flex justify-center items-center;
                    &:first-child {
                        @apply self-end;
                        span {
                            @apply flex gap-x-1 items-center;
                            svg {
                                filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.5));
                            }
                            &.failed {
                                @apply text-red-500 cursor-pointer;
                            }
                            &.waiting {
                                @apply cursor-default;
                                svg {
                                    @apply text-orange;
                                }
                            }
                        }
                    }
                    &:last-child {
                        svg {
                            @apply transition-all duration-300 ease-in-out cursor-pointer hover:scale-110;
                            font-size: 80px;
                            &.denied {
                                @apply text-red-500 pointer-events-none cursor-not-allowed;
                                text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);

                            }
                        }
                        filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.7));

                    }
                }
            }

            &::before {
                @apply absolute bg-blue rounded-2xl z-20 shadow-lg h-full w-full;
                content: '';
                left: -9px;
                top: -11px;
                height: 100%;
            }

            &::after {
                @apply absolute bg-orange rounded-2xl z-10 h-full w-full shadow-lg;
                content: '';
                right: -9px;
                bottom: -11px;
            }
        }
        &--action {
            @apply py-2 px-6 pr-10 relative;
            @screen lg {
                @apply absolute;
                top: calc(100% + 2rem);
            }
            border: 2px solid #2a274f;
            border-radius: 10px;

            &__container {
                @apply text-center text-blue text-xl font-medium whitespace-nowrap pb-4 pt-2;
                @screen lg {
                    @apply py-0;
                }


                b {
                    @apply font-extrabold;
                }
            }

            &__button {
                @apply px-6 bg-blue text-white text-sm rounded-2xl whitespace-nowrap absolute left-1/2;
                padding-bottom: 6px;
                padding-top: 6px;
                transform: translateX(-50%);
                top: calc(100% - 1rem);
               @screen lg {
                    @apply absolute top-1/2;
                    left: calc(100% - 2rem);
                    transform: translateY(-50%);
                }
            }
        }
    }
}
</style>
  