<template>
	<div class="home">
		<div class="home__background">
			<Splide v-if="slides" :slides="slides" />
		</div>
	</div>
</template>
  
<script>
import store from '@/store/index.js'
import Splide from '../components/Splide.vue'
export default {
	name: 'Home',
	components: {
		Splide,
	},
	data() {
		return {
			store,
			slides: false
		}
	},
	created() {
		this.setSlides(this.store.state.entities.slides)
	},
	mounted() {
		setTimeout(() => {
			this.store.commit('setLoading', false)
		}, 300)

		this.store.dispatch('setAccessEvent', {
            event: 'page_enter',
            value: 'home'
        })
	},
	methods: {
		setSlides(slides) {

			const result = []

			slides.forEach(slide => {
				result.push({
					desktop: slide.desktop,
					mobile: slide.mobile
				})
			})

			this.slides = result
		}
	},
	metaInfo: {
		title: 'Home | Organização Presidente',
		meta: [
			{ name: 'description', content: ' ORGANIZAÇÃO PRESIDENTE esta no mercado há 37 anos em Presidente Venceslau, no ramo de funerária, sempre buscando inovação para melhor atender a população!!' },
			{ name: 'keywords', content: 'Organização Presidente, Funerária, Funerária Presidente Venceslau' },
		]
	},
}
</script>
  
<style lang="scss">
.body{
	overflow: hidden;
}
.home {
	@apply grid items-start;
	grid-template-columns: 50% 1fr;
	padding: 4rem;

	@screen lg {
		@apply gap-x-10 pb-0;
		grid-template-columns: 35% auto;
		padding: 1rem 2.5% 0 2.5%;
	}

	@screen xl {
		@apply gap-x-16;
		padding: 5rem 7% 0 10%;
	}

	&__background {
		.splide--container {
			.splide__arrows {
				.splide__arrow--prev {
					width: 50px;
					height: 50px;
					top: 58%;
					background: #ccc !important;
					svg{
						fill: white;
					}
					@screen xl {
						left: 0.9%;
					}

					@screen 2xl {
						width: 57px;
						height: 57px;
						left: 1.3%;
					}
				}

				.splide__arrow--next {
					width: 50px;
					height: 50px;
					top: 58%;
					background: #ccc !important;
					svg{
						fill: white;
					}
					@screen xl {
						right: 0%;
					}

					@screen 2xl {
						width: 57px;
						height: 57px;
						right: 0.6%;
					}
				}
			}

		}

		@apply fixed inset-0;
		z-index: -1;

		img {
			@apply w-full h-full object-cover;
		}

	}

	&__infos {
		@apply pt-8;

		@screen lg {
			@apply pt-0;
		}

		&--image {
			@apply flex justify-center pb-4;
		}

		&--description {
			@apply p-6 rounded-md text-blue text-center font-medium text-[13px];
			border: 2px solid #262538;

			p:not(:last-child) {
				@apply pb-2;
			}
			width: 85%;
			text-shadow: 1.2px 1.2px 0.5px rgba(0, 0, 0, 0.2);
		}
	}

	&__arrows {
		@apply cursor-pointer;
		&--left img {
			filter: grayscale(100%);
			opacity: 0.5;
			left: 40px;
			position: absolute;
			top: 60%;
			transform: translateY(-50%);
		}

		&--right img {
			filter: grayscale(100%);
			opacity: 0.5;
			right: 40px;
			position: absolute;
			top: 60%;
			transform: translateY(-50%);
		}
	}

	@media (max-width: 640px) {
		grid-template-columns: 100%;
		padding: 2rem;

		.splide__arrow--prev, .splide__arrow--next { 
			display: none;
		}
	}
}
</style>
  