<template>
  <footer class="footer">
    <div class="footer--copyright">
      © {{ new Date().getFullYear() }} <b>Organização Presidente</b>
    </div>
    <div class="footer--vinco">
      <img src="/general/footer/vinco.png" />
      <span>
        Powered by <a href="https://www.instagram.com/agvinco" target="_blank">Vinco</a>
      </span>
    </div>
  </footer>
</template>

<script>

import store from '@/store/index.js'
export default {
  name: 'CustomFooter',
  data() {
    return {
      store
    }
  }
}

</script>

<style lang="scss">
.footer {
  @apply mt-14 w-full py-6 flex justify-center items-center gap-x-4 relative z-50 uppercase flex-wrap bg-white font-light select-none;
  color: #303030;
  font-size: 10px;
  &--copyright {
    @apply cursor-default;
  }
  &--vinco {
    @apply flex items-center gap-x-2 cursor-default;
    img {
      @apply cursor-pointer;
      height: 20px;
    }
  }

  b,a {
    @apply font-semibold;
  }
}</style>
