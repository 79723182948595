<template>
  <div :class="{ loading: loading }" id="app">
    <CustomMenu />
    <div class="transition--background">
      <responsive-image src="/velorio-online/background.png" />
    </div>
    <router-view v-if="initialized" class="page" />
    <div v-else></div>
    <CustomFooter />


            <div :class="{ 'obituario--modal': true, active: modal != false }">
                <div class="obituario--modal__mask" @click="close()" />
                <div class="obituario--modal__wrapper">
                  <div class="obituario--modal__wrapper--close" @click="close()">
                    <font-awesome-icon icon="xmark" />
                  </div>
                    <div class="obituario--modal__wrapper--container">
                        <div class="obituario--modal__wrapper--container__left">
                            <div class="obituario--modal__wrapper--container__left--content">
                                <img src="/desktop/obituario/icon.png" />
                            </div>
                        </div>
                        <div v-if=" modal != false" class="obituario--modal__wrapper--container__right">
                          <div class="obituario--modal__wrapper--container__right--name">{{ formatName(modal.name) }}</div>
                          <div class="obituario--modal__wrapper--container__right--division" />
                          <div class="obituario--modal__wrapper--container__right--content" v-html="modal.content" />
                        </div>
                    </div>
                </div>
            </div>
  </div>
</template>

<script>
import store from '@/store/index.js'
import axios from 'axios'

import CustomMenu from './components/CustomMenu'
import CustomFooter from './components/CustomFooter'

export default {
  name: 'App',
  components: {
    CustomMenu,
    CustomFooter
  },
  data() {
    return {
      store,
      axios
    }
  },
  async created() {

    this.axios.get('https://agencia-mercurio.s3.sa-east-1.amazonaws.com/json/2-04cd0216-102f-11ee-be56-0242ac120002.json', {
      headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
    }).then(async (response) => {
      const { data } = response

      this.$i18n.setLocaleMessage('pt', this.prepareJson(data.texts))

      this.store.dispatch('setEntity', {
        key: 'a-funeraria',
        data: data.entities['a-funeraria']
      })

      this.store.dispatch('setEntity', {
        key: 'slides',
        data: data.entities['slides']
      })

      this.store.dispatch('setEntity', {
        key: 'obituario',
        data: data.entities['obituario']
      })

      this.store.dispatch('setWebsiteDocuments', data.documents)
      this.store.dispatch('setWebsiteImages', data.images)

      this.store.dispatch('setAccessToken')

      this.store.dispatch('setInitialized', true)
    })

  },
  computed: {
    loading() {
      return this.store.state.loading
    },
    initialized() {
      return this.store.state.initialized
    },
    modal() {
      return this.store.state.modal
    }
  },
  watch: {
    '$route'() {
      this.store.commit('setLoading', true)
    }
  },
  methods: {
        prepareJson(texts) {
            const results = {}

            Object.keys(texts).forEach((key) => {
                const text = texts[key]

                results[key] = text.pt
            })

            return results
        },
        formatName(name) {
            let names = name.split(' ')

            if (names.length > 3) {
                return `${names[0]} ${names[1]} ${names[names.length - 1]}`
            } else {
                return name
            }
        },
        close() {
          this.store.dispatch('setModal', false)
        }
    }
}
</script>

<style lang="scss">
#app {
  @apply grid items-start;
  // height: 100vh;
  // overflow: overlay;
  // overflow: hidden;

  
.transition--background {
  @apply z-10 fixed left-0 top-0 w-full h-full;
  img {
    @apply w-full h-full object-cover;
  }
}
  .page {
    @apply duration-300 transition-all relative z-20 mx-auto;
    max-width: 1366px;
    min-height: calc(100vh - 120px);
    @screen lg {
      min-height: unset;
    }
  }

  &.loading {
    .page {
      @apply opacity-0;
    }
  }

  @screen lg {
    grid-template-rows: 172px minmax(calc(100vh - 172px), 1fr) auto;
  }


  .obituario--modal {
        @apply fixed left-0 w-full top-0 h-full flex justify-center items-center opacity-0 pointer-events-none transition-all duration-300;
        z-index: 1000;
        &.active {
            @apply opacity-100 pointer-events-auto;
        }
        &__mask {
            @apply absolute left-0 top-0 w-full h-full bg-black opacity-50;
        }

        &__wrapper {
          @apply  relative z-10 bg-white rounded-2xl w-90 mx-auto;
          border: 10px solid #2a274f;
          &--close {
            @apply absolute bg-orange flex justify-center items-center rounded-full text-white text-4xl cursor-pointer shadow-xl;
            right: 2rem;
            top: -1.5rem;
            height: 40px;
            width: 40px;
          }
          &--container {
            @apply grid gap-y-4 p-10 rounded-md;
            border: 5px solid #fecc17;
            &__left {
                @apply bg-blue rounded-3xl shadow-lg p-4;

                &--content {
                    @apply rounded-2xl flex justify-center items-center p-12;
                    border: 4px solid #fecc17;
                }
            }
            &__right {
              @apply grid gap-y-4;
              &--name {
                @apply text-xl font-extrabold text-blue text-center;
              }
              &--division {
                @apply bg-blue rounded-3xl;
                height: 8px;
              }
              &--content {
                @apply text-blue grid gap-y-2;
              }
            }
          }
        }
    }
    @screen lg {
      .obituario--modal {
        &__wrapper {
          width: 950px;
          &--container {
            @apply items-start gap-x-10;
            grid-template-columns: 1fr 1.5fr;
            &__left {

                &--content {
                    @apply p-20;
                    img {
                      @apply object-contain;
                      height: 180px;
                    }
                }
            }
            &__right {
              &--name {
                @apply text-4xl;
              }
              &--division {
                @apply bg-blue rounded-3xl;
                height: 8px;
              }
              &--content {
                @apply text-xl;
              }
            }
          }
        }
    }
    }
}
</style>
