<template>
    <div class="lined--title">
        <div :class="{ 'lined--title__row': true, unic: lines.length === 1 }" :key="index" v-for="(row, index) in lines">
            {{ row }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        lines: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style lang="scss">
.lined--title {
    @apply relative inline-flex justify-center;

    &__row {
        @apply relative inline bg-blue text-orange font-bold text-center py-1 px-3 rounded-lg;

        @screen lg {
            @apply text-xl;
        }

        &:first-child {
            @apply z-10;

            @screen lg {
                @apply pb-2;
            }
        }

        &:last-child:not(.unic) {
            @apply absolute left-1/2 whitespace-nowrap z-20 pt-0;
            transform: translateX(-50%);
            bottom: -1.25rem;
        }
    }
}</style>
