<template>
    <a :href="badgeLink"
      target="_blank"
      class="badge-button z-50"
      :style="{
        right: buttonPosition,
        width: badgeWidth,
        top: badgeTop,
        borderRadius: borderRadius,
      }"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <img :src="badgeImage" alt="Badge" class="badge-image" :style="{margin:marginImage}"/>
      <span class="badge-text py-4" v-show="showText">{{ badgeText }} <span>Saiba mais sobre ></span></span>
    </a>
  </template>
  
  <script>
  export default {
    props: {
      badgeImage: {
        type: String,
        required: true,
      },
      badgeText: {
        type: String,
        default: "",
      },
      badgeTop:{
        type: String,
        default: '12rem'
      },
      badgeLink:{
        type: String,
        default: '' 
      },
      badgeWidthMax:{
        type: String,
        default: '280px'
      },
      marginImage:{
        type: String,
        default: '-77px'
      },
    },
    data() {
      return {
        buttonPosition: "0px",
        badgeWidth: "35px",
        borderRadius: "60px 0 0 60px", // border-radius canto superior direito e inferior esquerdo
        showText: false,
      };
    },
    methods: {
      onMouseEnter() {
        this.badgeWidth = this.badgeWidthMax;
        this.borderRadius = "60px 0 0 60px"; // mantém o mesmo border-radius quando expandido
        this.showText = true;
      },
      onMouseLeave() {
        this.badgeWidth = "35px";
        this.borderRadius = "60px 0 0 60px"; // mantém o mesmo border-radius quando recolhido
        this.showText = false;
      },
    },
  };
  </script>
  
  <style>
  
  .badge-button {
    position: fixed;
    top: 10rem;
    display: flex;
    align-items: center;
    background-color: #262538;
    transition: width 0.3s ease, right 0.3s ease, border-radius 0.3s ease;
    cursor: pointer;
    height: 60px;
    z-index: 99999;
  }
  
  .badge-image {
    width: 88px;
    margin: -77px;
    max-width: 88px;
  }
  
  .badge-text {
    margin-left: 100px;
    color: #fecc17;
    display: flex;
    flex-direction: column;
    transition: opacity 0.3s ease;
    font-size: 24px;
    white-space: nowrap;
    font-weight: bold;
  }

  .badge-text span {
    font-size: 14px;
    margin-top: 3px; /* Espaçamento entre os spans */
    color: white;
  }

  @media (max-width: 640px) {
    .badge-button{
        display: none;
    }
}
  </style>
  