<template>
  <div class="funeraria">
    <div class="funeraria__background">
      <dynamic-image src="/funeraria/background.png" />
    </div>
    <div class="funeraria__infos">
      <div class="funeraria__infos--image">
        <responsive-image src="/funeraria/image.png" />
      </div>
      <div class="funeraria__infos--description" v-html="$t('a_funeraria.description')" />
    </div>
    <div class="funeraria__slide">
      <div class="funeraria__slide--categorias">
        <div class="funeraria__slide--categorias__item" v-for="(item, index) in categorias" :key="index"
          :class="{ 'selected': item.key === categoria }" @click="changeCategory(item.key)" v-html="$t(item.label)" />
      </div>
      <Slide v-if="slides" :slides="slides" />
    </div>
  </div>
</template>
  
<script>
import store from '@/store/index.js';
import Slide from '../components/Slide.vue';

export default {
  name: 'Funeraria',
  components: {
    Slide,
  },
  data() {
    return {
      store,
      slides: false,
      categoria: 'salas_de_velorio',
      categorias: [
        { key: "salas_de_velorio", label: "Salas de Velório" },
        { key: "salas_de_vip", label: "Salas VIP" },
        { key: "floricultura", label: "Floricultura" },
        { key: "frota", label: "Frota" },
        // { key: "equipe", label: "Equipe" },
        { key: "cremacao", label: "Cremação" },
      ]
    }
  },
  created() {
			this.setSlides(this.store.state.entities['a-funeraria'])
	},
  mounted() {
    setTimeout(() => {
      this.store.commit('setLoading', false);
    }, 300);

    this.store.dispatch('setAccessEvent', {
      event: 'page_enter',
      value: 'funeraria',
    });
  },
  methods: {
    setSlides(slides) {
      this.slides = slides.filter((slide) => slide.category === this.categoria).map((slide) => slide.image)
    },
    changeCategory(categoria) {
      this.categoria = categoria
      this.setSlides(this.store.state.entities['a-funeraria'])
    }
  },
  metaInfo: {
		title: 'Funerária | Organização Presidente',
		meta: [
			{ name: 'description', content: 'Funeral é um momento de dor e luto, em que é necessário cuidado e atenção para oferecer conforto e apoio aos familiares e amigos que sofrem com a perda de um ente querido. Na Organização Presidente, entendemos a importância desse momento e nos dedicamos a oferecer um atendimento humanizado e respeitoso em todos os nossos serviços funerários.' },
			{ name: 'keywords', content: 'Organização Presidente, Funerária, Funerária Presidente Venceslau' },
		]
	},
};
</script>
  
<style lang="scss">
.funeraria {
  @apply grid p-4 pb-20 gap-y-8 items-start;

  @screen lg {
    @apply gap-x-10 pb-0;
    grid-template-columns: 35% auto;
    padding: 1rem 2.5% 0 2.5%;
  }

  @screen xl {
    @apply gap-x-16;
    padding: 0rem 7% 0 7%;
  }

  &__background {
    @apply fixed inset-0;
    z-index: -1;

    img {
      @apply w-full h-full object-cover;
    }
  }

  &__infos {
    @apply pt-8;

    @screen lg {
      @apply pt-0;
    }

    &--image {
      @apply flex justify-center pb-4;
    }

    &--description {
      @apply p-6 rounded-md text-blue font-medium text-[13px];
      border: 2px solid #262538;

      p:not(:last-child) {
        @apply pb-2;
      }

      text-shadow: 1.2px 1.2px 0.5px rgba(0, 0, 0, 0.2);
    }
  }

  &__slide {
    &--categorias {
      display: flex;
      margin-bottom: 0.5rem;

      &__item {
        border-radius: 8px;
        background-color: #2a274f;
        flex: auto;
        padding: 4px 0px;
        color: white;
        text-align: center;
        margin-right: 3px;
        margin-bottom: 5px;
        cursor: pointer;
        font-size: 11px;

        &:last-child {
          margin-right: 0px;
        }

        &:hover {
          background-color: #fecc17;
        }

        &.selected {
          background-color: #fecc17;
        }

        @screen lg {
          font-size: 1rem;
        }
      }
    }
  }
}
</style>