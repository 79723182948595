<template>
    <div class="floricultura">
        <div class="floricultura--background">
            <dynamic-image src="/floricultura/background.png" />
        </div>
        <div class="floricultura--wrapper">
            <div class="floricultura--wrapper__image">
                <responsive-image src="/floricultura/image.png" />
            </div>
            <div class="floricultura--wrapper__infos">
                <div class="floricultura--wrapper__infos--row" v-html="$t('floricultura.calendar.1')" />
                <div class="floricultura--wrapper__infos--row" v-html="$t('floricultura.calendar.2')" />
                <div class="floricultura--wrapper__infos--row" v-html="$t('floricultura.calendar.3')" />
                <div class="floricultura--wrapper__infos--button">
                    <a :href="$t('link.floricultura')" target="_blank" v-html="$t('floricultura.buy')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store/index.js'

export default {
    name: 'Floricultura',
    data() {
        return {
            store
        }
    },
    mounted() {
        setTimeout(() => {
            this.store.commit('setLoading', false)
        }, 300)

        this.store.dispatch('setAccessEvent', {
            event: 'page_enter',
            value: 'floricultura'
        })

        const links = document.querySelectorAll('a[target="_blank"]')
        links.forEach((link) => {
            link.addEventListener('click', () => {
                const href = link.getAttribute('href')

                if (href && !href.includes(location.host)) {
                    this.store.dispatch('setAccessEvent', {
                        event: 'external_link',
                        value: href
                    })
                }
            })
        })
    },
    metaInfo: {
		title: 'Floricultura | Organização Presidente',
		meta: [
			{ name: 'description', content: '' },
			{ name: 'keywords', content: 'Organização Presidente, Funerária, Funerária Presidente Venceslau' },
		]
	},
}
</script>
  
<style lang="scss">
.floricultura {
    @apply p-4;

    &--background {
        @apply fixed left-0 top-0 w-full h-full;
        z-index: -1;

        img {
            @apply w-full h-full object-cover;
        }
    }

    &--wrapper {
        @apply py-8 grid gap-y-2 relative;

        &__image {

            picture,
            img,
            source {
                @apply w-90 mx-auto;
            }
        }

        &__infos {
            @apply pt-6 pb-8 pr-9;
            border: 2px solid #2a274f;
            border-radius: 10px;

            &--row {
                @apply text-right text-blue text-xl font-medium whitespace-nowrap;

                b {
                    @apply font-extrabold;
                }
            }

            &--button {
                @apply absolute px-4 py-2 bg-blue text-white text-sm rounded-2xl;
                right: 2.5rem;
                bottom: 1rem;
            }
        }
    }
}

@screen lg {
    .floricultura {
        padding: 0 15%;
        &--wrapper {
            @apply flex flex-wrap gap-y-4 relative;

            &__image {
                    @apply w-full;
                    picture,
                    img,
                    source {
                        @apply w-auto mx-0;
                        width: 528px;
                    }
            }

            &__infos {
                @apply relative py-8 px-11;
                line-height: 1;
                text-shadow: 1.2px 1.2px 0.5px rgba(0, 0, 0, 0.2);
                &--button {
                    bottom: -1.1rem;
                }
            }
        }
    }
}</style>
  