<template>
    <div class="home--frame" :style="homeFrameStyle">
        <div class="home--frame__before" :style="beforeStyle"></div>
        <div class="home--frame__after" :style="afterStyle"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            homeFrameStyle: '',
            beforeStyle: '',
            afterStyle: ''
        }
    },
    mounted() {
        this.initializeFrame()

        document.addEventListener('resize', this.initializeFrame)
    },
    methods: {
        initializeFrame() {
            // const logo = document.querySelector('.navbar--logo')
            const frame = document.querySelector('.home--frame')

            // const beforeWidth = logo.offsetLeft - frame.offsetLeft
            // this.beforeStyle = `width: ${beforeWidth}px`

            this.homeFrameStyle = `height: calc(100vh - ${frame.offsetTop}px - 4rem);`

        }
    }
}
</script>

<style lang="scss">
.home--frame {
    @apply absolute rounded-3xl hidden pointer-events-none;
    top: calc(100%);
    border: 3px solid #fecc17;
    left: 3%;
    right: 3%;
    filter: opacity(0.6) saturate(2);

    @screen lg {
        @apply block;
    }

    // &__before,
    // &__after {
    //     @apply absolute;
    //     height: 6px;
    //     background: yellow;
    // }
}
</style>
