<template>
    <div class="plans">
        <div class="plans--background">
            <dynamic-image src="/plans/background.png" />
        </div>
        <div class="plans--left">
            <div class="plans--left__title">
                <responsive-image src="/plans/image.png" />
            </div>
            <div class="plans--left__medals">
                <router-link to="/nossos-planos/diamante-mais" class="plans--left__medals--row">
                    <responsive-image src="/plans/diamond-medal.png" />
                </router-link>
                <router-link to="/nossos-planos/ouro-mais" class="plans--left__medals--row">
                    <responsive-image src="/plans/gold-medal.png" />
                </router-link>
                <router-link to="/nossos-planos/prata-mais" class="plans--left__medals--row">
                    <responsive-image src="/plans/silver-medal.png" />
                </router-link>

            </div>
            <div class="plans--left__legend" v-html="$t('plans.legend')" />
        </div>
        <div class="plans--right">
            <div class="plans--right__container">
                <div class="plans--right__container--header">
                    <lined-title :lines="['Benefícios', 'em Vida:']" class="plans--right__container--header__item" />
                    <lined-title :lines="['Diamante', 'Mais']" class="plans--right__container--header__item" />
                    <lined-title :lines="['Ouro', 'Mais']" class="plans--right__container--header__item" />
                    <lined-title :lines="['Prata', 'Mais']" class="plans--right__container--header__item" />
                </div>
                <div class="plans--right__container--list">
                    <div class="plans--right__container--list__row" :key="'row-' + i" v-for="(row, i) in benefits">
                        <div class="plans--right__container--list__row--item" :key="'item-' + j" v-for="(item, j) in row">
                            <span v-if="(typeof item === 'string')">{{ item }}</span>
                            <img v-else :src="`/general/plans${item ? '/check.png' : '/times.png'}`" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="plans--right__container">
                <div class="plans--right__container--header">
                    <div class="plans--right__container--header__item">
                        <lined-title :lines="['Assistência', 'Funeral 24h']"
                            class="plans--right__container--header__item" />
                    </div>
                </div>
                <div class="plans--right__container--list">
                    <div class="plans--right__container--list__row" :key="'row-' + i" v-for="(row, i) in assistance">
                        <div class="plans--right__container--list__row--item" :key="'item-' + j" v-for="(item, j) in row">
                            <span v-if="(typeof item === 'string')" v-html="item" />
                            <img v-else :src="`/general/plans${item ? '/check.png' : '/times.png'}`" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="plans--right__container">
                <div class="plans--right__container--header">
                    <lined-title :lines="['Amparo']" class="plans--right__container--header__item" />
                </div>
                <div class="plans--right__container--list">
                    <div class="plans--right__container--list__row" :key="'row-' + i" v-for="(row, i) in support">
                        <div class="plans--right__container--list__row--item" :key="'item-' + j" v-for="(item, j) in row">
                            <span v-if="(typeof item === 'string')">{{ item }}</span>
                            <img v-else :src="`/general/plans${item ? '/check.png' : '/times.png'}`" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="plans--right__legend" v-html="$t('plans.legend')"/>
        </div>
    </div>
</template>

<script>
import store from '@/store/index.js'

export default {
    name: 'NossosPlanos',
    data() {
        return {
            store,
            benefits: [
                [this.$t('plans.benefits.1'), true, true, true],
                [this.$t('plans.benefits.2'), true, true, true],
                [this.$t('plans.benefits.3'), true, true, false],
                [this.$t('plans.benefits.4'), '2', '1', false],
            ],
            assistance: [
                [this.$t('plans.assistance.1'), true, true, true],
                [this.$t('plans.assistance.2'), '900', '600', '200'],
                [this.$t('plans.assistance.3'), '3 e 4', '1 e 2', '1 e 2'],
                [this.$t('plans.assistance.4'), 'Luxo', 'Semi-Luxo', 'Padrão'],
            ],
            support: [
                [this.$t('plans.support.1'), true, true, true]
            ],
        }
    },
    mounted() {
        setTimeout(() => {
            this.store.commit('setLoading', false)
        }, 300)

        this.store.dispatch('setAccessEvent', {
            event: 'page_enter',
            value: 'nossos-planos'
        })
    },
    metaInfo: {
		title: 'Nossos Planos | Organização Presidente',
		meta: [
			{ name: 'description', content: '' },
			{ name: 'keywords', content: 'Organização Presidente, Funerária, Funerária Presidente Venceslau' },
		]
	},
}
</script>
  
<style lang="scss">
// color yellow: F0CA30 20% opacity
// color blue: 2A274F
.plans {
    @apply pt-4 pb-8 px-4 grid gap-y-4;

    &--background {
        @apply fixed left-0 top-0 w-full h-full;
        height: 100vh;
        width: 100vw;
        z-index: -1;

        img {
            @apply w-full h-full object-cover;
        }
    }

    &--left {
        @apply grid gap-y-2 px-4;

        &__medals {
            @apply relative flex items-center;

            &--row {
                @apply relative w-1/3;

                &:not(:nth-child(2)) {
                    img {
                        width: 105%;
                    }
                }

                &:nth-child(2) {
                    img {
                        @apply z-20;
                        width: 130%;
                    }
                }

                img {
                    @apply w-full relative left-1/2 z-10 transition-all duration-300 ease-in-out cursor-pointer;
                    transform: translateX(-50%);
                    max-width: unset;
                    &:hover {
                        @apply z-30;
                        transform: translateX(-50%) scale(1.01);
                        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
                    }
                }
            }
        }

        &__legend {
            @apply hidden;
        }
    }

    &--right {
        @apply grid gap-y-4;

        &__container {
            @apply grid gap-y-4 justify-center;

            &--header {
                @apply grid gap-x-2;
                grid-template-columns: 1.5fr 1fr 1fr 1fr
            }

            &--list {
                @apply grid px-3 pt-10 pb-4 rounded-xl;
                border: 2px solid #2A274F;
                margin-top: -1.5rem;
                margin-left: -0.5rem;
                margin-right: -0.5rem;

                &__row {
                    @apply grid gap-x-2 rounded-xl px-2 py-2;
                    grid-template-columns: 1.5fr 1fr 1fr 1fr;

                    &:nth-child(2n + 1) {
                        background: #2a274f40;
                    }

                    &:nth-child(2n) {
                        background: #f0ca3040;
                    }

                    &--item {
                        @apply flex justify-center items-center text-left text-xs font-bold;
                        line-height: 1;

                        &:first-child {
                            @apply justify-start;
                        }

                        img {
                            height: 12px;
                        }
                    }
                }
            }



            &:nth-child(2),
            &:nth-child(3) {
                .plans--right__container--header {
                    @apply pl-2;
                }
            }

            &:nth-child(3) {
                .plans--right__container--header {
                    @apply justify-items-start;
                }

                .plans--right__container--list {
                    @apply pt-6;
                    margin-top: -2rem;
                }
            }
        }

        &__legend {
            @apply text-xs;
        }
    }
}

@screen lg {
    .plans {
        @apply flex items-start gap-x-4;
        padding: 0rem 7.5% 2rem 7.5%;

        &--left {
            @apply gap-y-6 px-0 pr-8;

            &__title {
                @apply flex justify-center items-center;
            }

            &__medals {
                @apply px-8;

                &--row {
                    &:not(:nth-child(2)) {
                        img {
                            width: 115%;
                        }
                    }

                    &:nth-child(2) {
                        img {
                            width: 150%;
                        }
                    }
                }
            }

            &__legend {
                @apply block;
            }
        }

        &--right {
            @apply gap-y-2;

            &__container {
                @screen xxl {
                    margin-top: -1.25rem;
                }

                &--header {
                    grid-template-columns: 2fr 1fr 1fr 1fr
                }

                &--list {
                    @apply pt-12 pb-8;
                    margin-top: -2rem;
                    margin-left: -1.5rem;
                    margin-right: -1.5rem;

                    &__row {
                        @apply rounded-lg px-6 py-1;
                        grid-template-columns: 2fr 1fr 1fr 1fr;
                    }
                }

                &:nth-child(3) {
                    @apply block;

                    .plans--right__container--header {
                        @apply justify-start;

                        &__item {
                            @apply justify-self-start;
                        }
                    }

                    .plans--right__container--list {
                        @apply pt-8;
                        margin-top: -1.25rem;
                    }

                }
            }

            &__legend {
                @apply hidden;
            }
        }
    }
}</style>
  