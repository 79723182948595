<template>
    <div class="composed--div">
        <div class="content">
            <slot></slot>
        </div>
        <div class="background"></div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.composed--div {
    @apply relative;
    .content {
        @apply relative z-20 bg-blue shadow-sm text-center;
    }
    .background {
            @apply absolute bg-orange shadow-lg w-full h-full z-10;
            content: '';
            left: -1px;
            bottom: -3px;
            width: calc(100% + 2px);
            border-top-right-radius: 24px;
            border-bottom-right-radius: 24px; 
    }
}
</style>
