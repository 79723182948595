import axios from 'axios'
import api from '@/services/config'

function getIpApiInfo() {
    const url = 'https://ipapi.co/json/'
    return axios
        .get(url)
        .then((response) => response.data)
        .catch(() => {
            throw new Error('Error making request to IP API')
        })
}

export default {
    texts() {
        return api.get(`04cd0216-102f-11ee-be56-0242ac120002/website-texts`)
    },
    entity(payload) {
        return api.get(`04cd0216-102f-11ee-be56-0242ac120002/website-entities/${payload}`)
    },
    images() {
        return api.get(`04cd0216-102f-11ee-be56-0242ac120002/website-images`)
    },
    documents() {
        return api.get(`04cd0216-102f-11ee-be56-0242ac120002/website-documents`)
    },
    async setAccessToken(token) {
        const access = await getIpApiInfo()

        access.mobile = /Mobile/i.test(navigator.userAgent)
        access.redirect = document.referrer

        if (access.redirect === window.location.href) {
            access.redirect = '/'
        }

        access.initial_url = window.location.href

        return api.post(
            `04cd0216-102f-11ee-be56-0242ac120002/website-access`,
            {
                token,
                access
            }
        )
    },
    accesEvent(event, value, token) {
        return api.post(`04cd0216-102f-11ee-be56-0242ac120002/website-access-event`, {
            uuid: token,
            key: event,
            value
        })
    }
}
