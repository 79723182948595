<template>
  <splide class="splide--container" :options="options">
    <splide-slide class="splide--container__slide" v-for="item in slides" :key="item.id">
      <ResponsiveImage :src="item" />
    </splide-slide>
  </splide>
</template>

<script>
import ResponsiveImage from './ResponsiveImage.vue'

export default {
  components: {
    ResponsiveImage
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: {
        rewind: true,
        gap: '1rem',
        width: '100vw',
        height: '100vh',
        autoplay: true,
        pagination: false
      },
    };
  }
}
</script>