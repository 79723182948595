<template>
  <div class="div-container">

    <div class="abertura" v-if="abertura">
      <video class="video_abertura video-frame" :style="{ cursor: muted ? 'pointer' : 'auto' }" autoplay :muted="muted" ref="video_abertura" :src="videoURL"></video>
      <div id="aviso" ref="aviso_som" :style="{ display: muted ? 'block' : 'none'}">Clique para ativar o som</div>
    </div>
    <div v-else>
      <div class="div-cabecalho" v-if="online">

        <div class="box-container m-bottom">
          <div class="box-border">
            <div class="box-content">
              <h1>{{falecido}}</h1>
            </div>
          </div>
        </div>
        <div class="div-linha">
          <div class="pos-l"><b>Local do Sepultamento:</b> {{local_sepultamento}}</div>
          <div class="pos-r"><b>Local do Velório:</b> {{local_velorio}}</div>
        </div>
        <div class="div-linha">
          <div class="pos-l"><b>Data Inicial:</b> {{data_de_formatada}}</div>
          <div class="pos-r"><b>Data Final:</b> {{data_ate_formatada}}</div>
        </div>
      </div>
    </div>

    <div class="velorio" v-show="!abertura">
      <div class="camera" v-show="online">
        <div class="video-container">
          <video id="video" class="video-frame" controls autoplay muted ref="video_player"></video>
        </div>
      </div>
      <div class="offline" v-if="!online">
        <img src="/logo_azul.png" class="logo-avatar" alt="logo organização presidente">

        <div class="box-container m-top">
          <div class="box-border">
            <div class="box-content">
              {{mensagem_offline}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Connect from "/public/plugins/hls-connect"

export default {
  mixins: [Connect],
  data() {
    return {
      abertura: true,
      muted: true,
      videoURL: 'https://organizacaopresidente.com.br/sistema/wp-content/uploads/abertura/condolencias.mp4',

      online: false,
      erro_mensagem: null,

      falecido: null,
      data_de: null,
      hora_de: null,
      data_ate: null,
      hora_ate: null,
      local_velorio: null,
      local_sepultamento: null
    }
  },
  mounted() {
    this.getInfo(true)

    this.$refs.video_abertura.addEventListener('ended', () => this.abertura = false)
    this.$refs.video_abertura.addEventListener('click', () => this.muted = false)
    this.$refs.aviso_som.addEventListener('click', () => this.muted = false)
  },
  methods: {
    getInfo: function (start) {
      const key = this.$route.params.key
      const par = new URLSearchParams([['channel', key]])

      axios.post('https://cameras.organizacaopresidente.com.br/get-info', par.toString())
          .then(({data}) => {
            this.erro_mensagem = null

            if (data.status) {
              this.falecido = data.name
              this.data_de = data.data_de
              this.hora_de = data.hora_de
              this.data_ate = data.data_ate
              this.hora_ate = data.hora_ate
              this.local_velorio = data.local_velorio
              this.local_sepultamento = data.local_sepultamento
              this.online = data.online
            } else if (start) {
              this.online = false
              this.erro_mensagem = data.message
            }

            if (start) {
              this.montar()
              this.autoHide()
            }

            setTimeout(() => this.getInfo(false), 5 * 60 * 1000)
          })
          .catch(e => {
            let status, message

            if (e.response) {
              status = e.response.status
              message = `(${status}) ${e.response.data}`
            } else if (e.request) {
              message = e.message
            } else {
              message = e.message
            }

            this.online = false
            this.erro_mensagem = message
          })
    },
    montar: function () {
      /* eslint-disable no-unused-vars */
      /* eslint-disable no-undef */
      const id = this.$route.params.key

      this.connect_start('https://cameras.organizacaopresidente.com.br', id)
    },
    autoHide: function() {
      const limite = new Date(`${this.get_data_ate} ${this.hora_ate}`);
      const agora = new Date()

      if (agora > limite) {
        this.online = false
        return;
      }

      setTimeout(() => this.autoHide(), 1000)
    }
  },
  computed: {
    get_data_de() {
      let separador = this.data_de.includes('/') ? '/' : '-'
      let partes = this.data_de.split(separador)
      if (partes[0].length == 4) {
        return this.data_ate
      } else {
        return `${partes[2]}-${partes[1]}-${partes[0]}`
      }
    },
    get_data_ate() {
      let separador = this.data_ate.includes('/') ? '/' : '-'
      let partes = this.data_ate.split(separador)
      if (partes[0].length == 4) {
        return this.data_ate
      } else {
        return `${partes[2]}-${partes[1]}-${partes[0]}`
      }
    },
    data_de_formatada() {
      return `${(this.get_data_de || '').split('-').reverse().join('/')} ${(this.hora_de || '')}`
    },
    data_ate_formatada() {
      return `${(this.get_data_ate || '').split('-').reverse().join('/')} ${(this.hora_ate || '')}`
    },
    data_final_formatada() {
      return `${(this.get_data_ate || '').split('-').reverse().join('/')} às ${(this.hora_ate || '')}`
    },
    mensagem_offline() {
      if (this.erro_mensagem) {
        return `Ocorreu um erro, informe a seguinte mensagem ao suporte: ${this.erro_mensagem}`
      } else if (this.data_ate) {
        return `Este velório encerrou em ${this.data_final_formatada}`
      } else {
        return 'Este velório está offline'
      }
    }
  }
}
</script>

<style scoped>
.div-container {
  padding: 1.7rem;
}
.video_abertura {
  width: 100%;
}

.video-frame {
  background: #000;
  border: 3px solid goldenrod;
  max-width: 100%;
  border-radius: 20px;
}

.logo-avatar {
  margin: 0 auto;
  display: block;
  max-height: 300px;
  max-width: 100%;
}

.div-cabecalho {
  display: flex;
  flex-direction: column;

  margin: 0 auto 10px auto
}

.titulo {
  text-align: center;
  margin-bottom: 10px;
}

.titulo h1 {
  color: #2a274f;
  display: block;
  font-size: 2em;
  margin: 0 0 0.67em 0;
  font-weight: bold;
}

.titulo h2 {
  margin: 0;
}

.div-linha {
  display: flex;
}

.div-linha > div {
  flex-grow: 1;
  flex-basis: 33%;
  margin-bottom: 5px;
}

.offline {
  width: 100%;
  text-align: center;
}

.velorio {
  width: 100%;
  padding: 5px;
}

.velorio .camera {
  /*max-width: 960px;*/
  margin: 0 auto;
}

.velorio .video-container {
  width: 100%;
}

.velorio .camera #video {
  margin: 0 auto;
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 4 / 3;
}

.pos-l {
  text-align: left;
}
.pos-c {
  text-align: center;
}
.pos-r {
  text-align: right;
}

.box-container {
  display: flex;
  color: #fff;
}

.box-container.m-bottom {
  margin-bottom: 20px;
}

.box-container.m-top {
  margin-top: 40px;
}

.box-border {
  flex-grow: 1;
  align-self: center;
  text-align: center;
  /*margin-left: 1em;*/
  /*margin-right: 1em;*/
}

.box-content {
  background-color: royalblue;
  padding: 1em 1rem;
  border-width: 0 8px 8px 0;
  border-color: goldenrod;
  border-style: solid;
  border-radius: 20px;
  font-weight: bold;
}

.abertura {
  position: relative;
}

#aviso {
  position: absolute;
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  top: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px 30px;
  cursor: pointer;
  border-radius: 5px;
}
</style>z 