import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '/src/views/Home'
import VelorioOnline from '/src/views/VelorioOnline'
import NossaHistoria from '/src/views/NossaHistoria'
import Funeraria from '/src/views/Funeraria'
import Floricultura from '/src/views/Floricultura'
import NossosPlanos from '/src/views/NossosPlanos'
import Plano from '/src/views/Plano'
import Obituario from '/src/views/Obituario'
import Velorio from '/src/views/Velorio'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/velorio-online',
    name: 'velorio_online',
    component: VelorioOnline
  },
  {
    path: '/nossa-historia',
    name: 'nossa_historia',
    component: NossaHistoria
  },
  {

    path: '/a-funeraria',
    name: 'a_funeraria',
    component: Funeraria
  },
  {
    path: '/floricultura',
    name: 'floricultura',
    component: Floricultura
  },
  {
    path: '/nossos-planos',
    name: 'nossos_planos',
    component: NossosPlanos,
  },
  {
    path: '/nossos-planos/:label',
    name: 'plano',
    component: Plano,
  },
  {
    path: '/obituario',
    name: 'obituario',
    component: Obituario,
  },
  {
    path: '/velorio/:key',
    name: 'velorio',
    component: Velorio,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router