<template>
    <div class="obituario">
        <!-- <Badge :badgeImage="'/general/obituario/flor.png'" :badgeTop="'12rem'" :badgeWidthMax="'350px'" :badgeText="$t('badge.flor.text')" :badgeLink="$t('link.floricultura')" :marginImage="'-55px'"/> -->
        <div class="obituario--background">
            <dynamic-image src="/obituario/background.png" />
        </div>
        <div class="obituario--header">
            <responsive-image :src="`/obituario/title.png`" />
            <div class="obituario--header__description">
                <div class="obituario--header__description--row" v-html="$t('obituario.description')" />
                <div class="obituario--header__description--button">
                    <a :href="$t('link.floricultura')" target="_blank">
                        {{ $t('obituario.button') }} >
                    </a>
                </div>
            </div>
        </div>
        <transition-group name="fade" mode="out-in" class="obituario--list">
            <div key="obituario-item-title" class="obituario--list__item title">
                <div class="obituario--list__item--header">
                    <responsive-image :src="`/obituario/title.png`" />
                    <div class="obituario--list__item--header__description">
                        <div class="obituario--list__item--header__description--row" v-html="$t('obituario.description')" />
                        <div class="obituario--list__item--header__description--button">
                            <a :href="$t('link.floricultura')" target="_blank">
                                {{ $t('obituario.button') }} >
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div :key="'obituario-item-' + item.id" v-for="item in filteredObituario" class="obituario--list__item" @click="open(item)">
                <div class="obituario--list__item--cover">
                    <div class="obituario--list__item--cover__content">
                        <responsive-image src="/obituario/icon.png" />
                    </div>
                </div>
                <div class="obituario--list__item--name">
                    {{ formatName(item.name) }}
                </div>
            </div>
        </transition-group>

        <div class="obituario--pagination">
            <div @click="changePage(item.page)" :key="'pagination-' + item.page" class="obituario--pagination__item" v-for="item in pagination">
                {{ item.page }}
            </div>
        </div>

    </div>
</template>

<script>
import store from '@/store/index.js'
// import Badge from '../components/Badge.vue'

export default {
    name: 'Obituario',
    data() {
        return {
            store,
            item: {},
            modal: false,
            minIndex: 0,
            maxIndex: 39,
            obituario: []
        }
    },
    // components:{
    //     Badge
    // },
    created() {
        this.setObituario(this.store.state.entities.obituario)
	},
    computed: {
            filteredObituario() {
                    return this.obituario.filter((item, index) => index >= this.minIndex && index <= this.maxIndex)
            },
            pagination() {
                if (this.obituario) {
                    let pagination = []
                    let pages = Math.ceil(this.obituario.length / 39)

                    for (let i = 0; i < pages; i++) {
                        pagination.push({
                            page: i + 1
                        })
                    }

                    return pagination
                } else {
                    return []
                }
            }
    },
    mounted() {
        setTimeout(() => {
            this.store.commit('setLoading', false)
        }, 300)

        this.store.dispatch('setAccessEvent', {
            event: 'page_enter',
            value: 'obituario'
        })

        const links = document.querySelectorAll('a[target="_blank"]')
        links.forEach((link) => {
            link.addEventListener('click', () => {
                const href = link.getAttribute('href')

                if (href && !href.includes(location.host)) {
                    this.store.dispatch('setAccessEvent', {
                        event: 'external_link',
                        value: href
                    })
                }
            })
        })
    },
    methods: {
        setObituario(obituario) {
            this.obituario = obituario
        },
        changePage(page) {
            this.minIndex = (page - 1) * 39
            this.maxIndex = page * 39

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
        open(item) {
            this.store.dispatch('setModal', item)
        },
        close() {
            this.store.dispatch('setModal', {})
        },
        formatName(name) {
            let names = name.split(' ')

            if (names.length > 3) {
                return `${names[0]} ${names[1]} ${names[names.length - 1]}`
            } else {
                return name
            }
        }
    },
    metaInfo: {
		title: 'Obituário | Organização Presidente',
		meta: [
			{ name: 'description', content: '' },
			{ name: 'keywords', content: 'Organização Presidente, Funerária, Funerária Presidente Venceslau' },
		]
	},
}
</script>
  
<style lang="scss">
.obituario {
    @apply pt-6 pb-8 px-6 grid gap-y-4;

    &--background {
        @apply fixed left-0 top-0 w-full h-full;
        height: 100vh;
        width: 100vw;
        z-index: -1;

        img {
            @apply w-full h-full object-cover;
        }
    }

    &--header {
        &__description {
            @apply pt-6 pb-8 px-9 relative;
            border: 2px solid #2a274f;
            border-radius: 10px;

            &--row {
                @apply text-center text-blue text-xl font-medium whitespace-nowrap;

                b {
                    @apply font-extrabold;
                }
            }

            &--button {
                @apply absolute px-4 py-2 bg-blue text-white text-sm rounded-2xl;
                right: 3rem;
                bottom: -1rem;
            }
        }
    }

    &--list {
        @apply grid grid-cols-3 gap-6 pt-8;

        &__item {
            @apply grid gap-y-2;

            &.title {
                @apply hidden;
            }

            &--cover {
                @apply p-1 bg-blue rounded-xl shadow-lg;

                &__content {
                    @apply rounded-xl p-4 flex justify-center items-center;
                    border: 4px solid #fecc17;
                }
            }

            &--name {
                @apply bg-blue text-orange px-3 py-1 text-xs font-bold rounded-xl text-center shadow-lg uppercase;
            }
        }
    }

    &--pagination {
        @apply flex justify-center items-center gap-x-2 gap-y-3 pt-12 flex-wrap;
        &__item {
            @apply flex justify-center items-center bg-blue text-orange text-xs font-bold rounded-lg cursor-pointer;
            height: 30px;
            width: 30px;
        }
    }
}

@screen lg {
    .obituario {
        padding: 0rem 7.5% 2rem 7.5%;

        &--header {
            @apply hidden;
        }

        &--list {
            @apply grid-cols-5 gap-y-10;
            grid-auto-rows: 1fr;

            &__item {
                @apply grid gap-y-2;
                grid-template-rows: 1fr auto;

                &.title {
                    @apply block px-12 pb-8;
                    grid-column: 1/3;
                }

                &--header {
                    &__description {
                        @apply pt-6 pb-8 px-9 relative;
                        border: 2px solid #2a274f;
                        border-radius: 10px;

                        &--row {
                            @apply text-center text-blue text-xl font-medium whitespace-nowrap;

                            b {
                                @apply font-extrabold;
                            }
                        }

                        &--button {
                            @apply absolute px-4 py-2 bg-blue text-white text-sm rounded-2xl cursor-pointer;
                            right: 3rem;
                            bottom: -1rem;
                        }
                    }
                }

                &--cover {
                    @apply p-2 cursor-pointer;

                    &__content {
                        @apply h-full;
                        border: 5px solid #fecc17;

                        picture {
                            @apply transition-all duration-300
                        }

                        &:hover {
                            picture {
                                transform: scale(0.95);
                            }
                        }
                    }
                }

                &--name {
                    @apply text-sm cursor-pointer whitespace-nowrap;
                }
            }
        }
    }
}
</style>
  