<template>
    <div class="nossa--historia">
        <div class="nossa--historia__background">
            <dynamic-image src="/nossa-historia/background.png" />
        </div>
        <div class="nossa--historia__infos">
            <div class="nossa--historia__infos--image">
                <responsive-image src="/nossa-historia/image.png" />
            </div>
            <div class="nossa--historia__infos--description" v-html="$t('nossa_historia.description')" />
        </div>
        <div class="nossa--historia__dropdown">
            <div :key="dropdown.id" v-for="dropdown in dropdowns" class="nossa--historia__dropdown--item">
                <div class="nossa--historia__dropdown--item__label" @click="expandContent(dropdown)">
                    <p v-if="dropdown.id == 1" v-html="$t('nossa_historia.label.missao')" />
                    <p v-if="dropdown.id == 2" v-html="$t('nossa_historia.label.visao')" />
                    <p v-if="dropdown.id == 3" v-html="$t('nossa_historia.label.valores')" />
                    <span><img src="/desktop/nossa-historia/icons/plus.png" alt=""></span>
                </div>
                    <div v-if="dropdown.id == 1" class="nossa--historia__dropdown--item__content" v-show="dropdown.expanded" v-html="$t('nossa_historia.description.missao')"/>
                    <div v-if="dropdown.id == 2" class="nossa--historia__dropdown--item__content" v-show="dropdown.expanded" v-html="$t('nossa_historia.description.visao')"/>
                    <div v-if="dropdown.id == 3" class="nossa--historia__dropdown--item__content" v-show="dropdown.expanded" v-html="$t('nossa_historia.description.valores')"/>
                <div class="nossa--historia__dropdown--item__content--empty" v-show="!dropdown.expanded">
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import store from '@/store/index.js'

export default {
    name: 'NossaHistoria',
    data() {
        return {
            store,
            dropdowns: [
                {
                    id:1,
                    label:"Missão:",
                    expanded:false
                },
                {
                    id:2,
                    label:"Visão:",
                    expanded:false
                },
                {
                    id:3,
                    label:"Valores:",
                    expanded:false
                }
            ]
        }
    },
    mounted() {
        setTimeout(() => {
            this.store.commit('setLoading', false)
        }, 300)

        this.store.dispatch('setAccessEvent', {
            event: 'page_enter',
            value: 'nossa-historia'
        })
    },
    methods: {
        expandContent(dropdown) {
            dropdown.expanded = !dropdown.expanded;
        }
    },
    metaInfo: {
		title: 'Nossa História | Organização Presidente',
		meta: [
			{ name: 'description', content: 'São mais de 41 anos cuidando da nossa e de tantas outras. Nossa história começou em 1982, quando viemos de Tupã para investir em Presidente Venceslau. Em vida, oferecemos serviços de saúde completos, com descontos em consultas, clínicas e exames. Já na hora da despedida, estamos por perto para proporcionar uma homenagem respeitosa e digna àqueles que partiram, amparando e tranquilizando quem mais precisa.' },
			{ name: 'keywords', content: 'Organização Presidente, Funerária, Funerária Presidente Venceslau' },
		]
	},
}
</script>
  
<style lang="scss">
.nossa--historia {
    @apply grid p-4 pb-20 gap-y-8 items-start;

    @screen lg {
        @apply gap-x-10 pb-0;
        grid-template-columns: 35% auto;
        padding: 1 2.5% 0 2.5%;
    }

    @screen xl {
        @apply gap-x-16;
        padding: 0 7% 0 7%;
    }

    &__background {
        @apply fixed left-0 top-0 w-full h-full;
        z-index: -1;

        img {
            @apply w-full h-full object-cover;
        }
    }

    &__infos {
        @apply pt-8;
        @screen lg {
            @apply pt-0;
        }
        &--image {
            @apply flex justify-center pb-4;
        }

        &--description {
            @apply p-6 rounded-md text-blue font-medium text-[13px];
            border: 2px solid #262538;
            
            p:not(:last-child) {
                @apply pb-2;
            }

            text-shadow: 1.2px 1.2px 0.5px rgba(0, 0, 0, 0.2);
        }
    }

    &__dropdown {
        @screen lg {
            @apply grid justify-items-end;
        }

        @screen xl {
            @apply grid justify-items-end;
        }
        @apply grid justify-items-center;

        &--item {
            @screen lg {
                width: 55%;
            }

            @screen xl {
                width: 55%;
            }
            width: 100%;
            @apply pb-2;
            &__label {
                @apply font-bold bg-orange rounded-full text-blue text-2xl text-center mx-auto flex items-center justify-between cursor-pointer relative;
                width: 70%;
                p {
                    @apply text-center flex-1;
                }
                span {
                    img {
                        @apply w-[80%] h-[10%] mr-1;
                    }
                    @apply inline-flex items-center justify-center;
                }
            }

            &__content {
                @apply text-white text-[0.813rem] p-6 bg-blue rounded-lg leading-4;
                z-index: -1;
                margin-top: -14px;
                &--empty{
                    @apply p-[0.6rem] bg-blue rounded-lg;
                    margin-top: -14px;
                }
            }
        }
    }
}</style>
  