<template>
    <div class="composed--title">
        <div class="composed--title__row" v-html="lines[0]" />
        <div class="w-full"></div>
        <div class="composed--title__row" v-html="lines[1]" />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        }
    },
    data() {
        return {}
    },
    computed: {
        lines() {
            const first = this.title.split('\n')[0]
            const last = this.title.replace(first + '\n', '').replace('\n', '<br>')
            return [first, last]
        },
    }
}
</script>

<style lang="scss">
.composed--title {
    @apply relative flex flex-wrap;

    &__row {
        @apply font-bold px-3 inline-block rounded-md;

        &:first-child {
            @apply bg-orange text-blue px-3 py-0 z-20 text-sm;
            margin-left: -0.6px;
            border-bottom-left-radius: 0;
        }

        &:last-child {
            @apply text-white bg-blue px-3 pt-2 pb-1 z-10;

            @screen lg {
                @apply text-base leading-none pb-2;
            }

            br {
                @apply hidden;

                @screen lg {
                    @apply inline-block;
                }
            }

            margin-top: -0.5rem;
        }
    }
}</style>
