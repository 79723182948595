<template>
  <div class="slider--container" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
    <div class="slider--container__wrapper--arrow--prev" @click="prevSlide">
      <svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151 151">
        <rect class="cls-1" x="0" y="0" width="151" height="151" rx="75.5" ry="75.5" />
        <path class="cls-2" d="M104.47,32.5l-53.53,34.89c-5.86,3.82-5.86,12.41,0,16.23l53.53,34.89" />
      </svg>
    </div>
    <div class="slider--container__wrapper--arrow--next" @click="nextSlide">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151 151">
        <rect class="cls-1" x="0" y="0" width="151" height="151" rx="75.5" ry="75.5" />
        <path class="cls-2" d="M46.78,32.25l53.53,34.89c5.86,3.82,5.86,12.41,0,16.23l-53.53,34.89" />
      </svg>
    </div>
    <div class="slider--container__wrapper">
      <div class="slider--container__wrapper--slider" :style="{ transform: `translateX(-${activeIndex * 100}%)` }">
        <div class="slider--container__wrapper--slider__slide" v-for="(item, index) in slides" :key="index">
          <img :src="baseUrl + item" />
        </div>
      </div>
    </div>
    <div class="slider--container__thumbnails">
      <div class="slider--container__thumbnails--arrow--prev" @click="prevSlide">
        <svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151 151">
          <rect class="cls-1" x="0" y="0" width="151" height="151" rx="75.5" ry="75.5" />
          <path class="cls-2" d="M104.47,32.5l-53.53,34.89c-5.86,3.82-5.86,12.41,0,16.23l53.53,34.89" />
        </svg>
      </div>
      <div class="slider--container__thumbnails--thumbnail" v-for="(item, index) in slides" :key="index"
        :class="{ active: index === activeIndex }" @click="slideTo(index)">
        <img :src="baseUrl + item" />
      </div>
      <div class="slider--container__thumbnails--arrow--next" @click="nextSlide">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151 151">
          <rect class="cls-1" x="0" y="0" width="151" height="151" rx="75.5" ry="75.5" />
          <path class="cls-2" d="M46.78,32.25l53.53,34.89c5.86,3.82,5.86,12.41,0,16.23l-53.53,34.89" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/index.js'
export default {
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      store,
      activeIndex: 0,
      touchStartX: 0,
      touchEndX: 0
    };
  },
  computed: {
    baseUrl() {
      return this.store.state.baseUrl
    },
  },
  methods: {
    slideTo(index) {
      this.activeIndex = index;
    },
    prevSlide() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      } else {
        this.activeIndex = this.slides.length - 1;
      }
    },
    nextSlide() {
      if (this.activeIndex < this.slides.length - 1) {
        this.activeIndex++;
      } else {
        this.activeIndex = 0;
      }
    },
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      this.touchEndX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      const touchThreshold = 50;

      if (this.touchEndX - this.touchStartX > touchThreshold) {
        this.prevSlide();
      } else if (this.touchStartX - this.touchEndX > touchThreshold) {
        this.nextSlide();
      }

      this.touchStartX = 0;
      this.touchEndX = 0;
    }
  },
};
</script>

<style lang="scss">
.cls-1 {
  fill: #f0ca30;
}

.cls-2 {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 10px;
}

.slider--container {
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    @apply absolute bg-blue rounded-lg z-10 shadow-lg h-[62%] w-[99%];
    content: '';
    left: -7px;
    top: -6px;
  }

  &::after {
    @apply absolute bg-orange rounded-lg z-10 h-[62%] w-[99%] shadow-lg;
    content: '';
    right: -7px;
    bottom: 126px;
  }

  @screen lg {
    @apply gap-x-10 pb-0;
    grid-template-columns: 35% auto;
    padding: 1rem 2.5% 0 2.5%;

    &::before {
      @apply absolute bg-blue rounded-lg z-10 shadow-lg h-[62%] w-[99%];
      content: '';
      left: -7px;
      top: -6px;
    }

    &::after {
      @apply absolute bg-orange rounded-lg z-10 h-[62%] w-[99%] shadow-lg;
      content: '';
      right: -7px;
      bottom: 126px;
    }
  }

  @screen xl {
    @apply gap-x-16;
    padding: 0rem 7% 0 7%;

    &::before {
      @apply absolute bg-blue rounded-lg z-10 shadow-lg h-[75%] w-[85%];
      content: '';
      left: 41px;
      top: -7px;
    }

    &::after {
      @apply absolute bg-orange rounded-lg z-10 h-[74%] w-[86%] shadow-lg;
      content: '';
      right: 40px;
      bottom: 124px;
    }
  }

  @media (max-width: 640px) {
      .slider--container__wrapper--arrow--prev,
      .slider--container__wrapper--arrow--next {
        display: none;
      }
    }
  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 50;
    
    &--arrow--prev {
      position: absolute;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 60;
      left: 20px;
      top: 32%;
    }

    &--arrow--next {
      position: absolute;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 60;
      right: 20px;
      top: 32%;
    }

    &--slider {
      display: flex;
      position: relative;
      transition: transform 0.5s ease;

      &__slide {
        flex: 0 0 100%;
        width: 100%;

        img {
          width: 100%;
          border-radius: 10px;

        }

      }

    }
  }

  &__thumbnails {
    @apply bg-blue rounded-lg shadow-lg p-2 pb-[0.3rem];
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    &--arrow--prev {
      position: absolute;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 60;
      left: 31px;
      top: 85%;

      rect {
        fill: gray;
        opacity: 0.5;
      }
    }

    &--arrow--next {
      position: absolute;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 60;
      right: 31px;
      top: 85%;

      rect {
        fill: gray;
        opacity: 0.5;
      }
    }

    &--thumbnail {
      cursor: pointer;

      img {
        width: 150px;
        height: 100px;
        object-fit: cover;
        border-radius: 10px;
      }

      &.active {
        border: 4px solid #fecc17;
        opacity: 0.8;
        border-radius: 15px;
      }
    }

    @media (max-width: 640px) {
      .slider--container__thumbnails--arrow--prev,
      .slider--container__thumbnails--arrow--next {
        display: none;
      }
    }
  }
}</style>
