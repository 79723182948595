import axios from 'axios';

// Create a custom Axios instance
const instance = axios.create({
  // Configure the instance with your desired options
  // baseURL: 'http://localhost:8000/803572d6-d988-11ed-afa1-0242ac120002/',
  baseURL: 'https://api.mercurio.marketing/04cd0216-102f-11ee-be56-0242ac120002/',
  // Other options...
});

// Export the custom Axios instance
export default instance;