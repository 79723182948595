<template>
    <div class="plan">
        <div class="plan--background">
            <dynamic-image src="/plans/background.png" />
        </div>
        <div class="plan--left">
            <responsive-image :src="`/plans/${plan}/title.png`" />
            <responsive-image :src="`/plans/${plan}/medal.png`" />
        </div>
        <div class="plan--right">
            <div class="plan--right__content">
                <div class="plan--right__content--container">
                    <div class="plan--right__content--container__title">
                        {{ $t("plan.benefits") }}
                    </div>
                    <div class="plan--right__content--container__info" v-html="$t('plan.' + plan + '.benefits')" />
                </div>
                <div class="plan--right__content--container">
                    <div class="plan--right__content--container__title">
                        {{ $t("plan.assistance") }}
                    </div>
                    <div class="plan--right__content--container__info" v-html="$t('plan.' + plan + '.assistance')" />
                </div>
                <div class="plan--right__content--container">
                    <div class="plan--right__content--container__title">
                        {{ $t("plan.support") }}
                    </div>
                    <div class="plan--right__content--container__info" v-html="$t('plan.' + plan + '.support')" />
                </div>
                <div class="plan--right__content--legend" v-html="$t('plans.legend')" />

            </div>
            <div class="plan--right__extra">
                <div class="plan--right__extra--container" v-if="plan !== 'silver'">
                    <composed-title class="plan--right__extra--container__title" :title="$t('plan.unividas')" />
                    <a href="https://redeunividas.com.br/" target="_blank" class="plan--right__extra--container__info cursor-pointer"
                        v-html="$t('plan.unividas.info')" />
                </div>
                <div class="plan--right__extra--container">
                    <composed-title class="plan--right__extra--container__title" :title="$t('plan.partners')" />
                    <div @click="downloadFile()" class="plan--right__extra--container__info cursor-pointer"
                        v-html="$t('plan.partners.info')" />
                </div>
                <div class="plan--right__extra--container">
                    <composed-title class="plan--right__extra--container__title" :title="$t('plan.equipment_loan')" />
                    <div class="plan--right__extra--container__info" v-html="$t('plan.equipment_loan.info')" />
                </div>
                <div class="plan--right__extra--container" v-if="plan !== 'silver'">
                    <composed-title class="plan--right__extra--container__title" :title="$t('plan.cremation')" />
                    <div class="plan--right__extra--container__info" v-html="$t('plan.cremation.info')" />
                </div>
            </div>
            <div class="plan--right__legend" v-html="$t('plans.legend')" />
        </div>
    </div>
</template>

<script>
import store from '@/store/index.js'
import api from '@/services/config'

export default {
    name: 'Plano',
    data() {
        return {
            api,
            store,
            documents: []
        }
    },
    created() {
        this.store.dispatch('getWebsiteDocuments').then(() => {
            this.documents = this.store.state.website_documents
        })
    },
    mounted() {
        setTimeout(() => {
            this.store.commit('setLoading', false)
        }, 300)

        this.store.dispatch('setAccessEvent', {
            event: 'page_enter',
            value: 'plano-' + this.$route.params.label
        })
    },
    computed: {
        plan() {
            const label = this.$route.params.label
            const summary = {
                'diamante-mais': 'diamond',
                'ouro-mais': 'gold',
                'prata-mais': 'silver'
            }

            return summary[label]
        },
        baseUrl() {
            return this.store.state.baseUrl
        }
    },
    methods: {
        downloadFile() {
            const filepath = this.baseUrl + this.documents.pdf_partners
            const filename = this.documents.pdf_partners.split('/').pop()
            this.api
                .get(filepath, { responseType: "blob" })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error => {
                    console.error("Error downloading file:", error);
                });
        }
    },
    metaInfo() {
		return {
            title: `${this.$t('navbar.' + this.plan)} | Organização Presidente`,
            meta: [
                { name: 'description', content: '' },
                { name: 'keywords', content: 'Organização Presidente, Funerária, Funerária Presidente Venceslau' },
            ]
        }
	},
}
</script>
  
<style lang="scss">
.plan {
    @apply pt-6 pb-8 px-6 grid gap-y-4;

    &--background {
        @apply fixed left-0 top-0 w-full h-full;
        height: 100vh;
        width: 100vw;
        z-index: -1;

        img {
            @apply w-full h-full object-cover;
        }
    }

    &--left {
        @apply grid gap-x-4 items-center;
        grid-template-columns: 200px auto;

    }

    &--right {
        @apply grid gap-y-12;

        &__content {
            @apply grid gap-y-2;

            &--container {
                @apply pt-6 px-4 pb-6 rounded-xl relative;
                border: 2px solid #2A274F;

                &__title {
                    @apply font-bold text-orange bg-blue px-3 py-1 inline-block rounded-md text-lg absolute;
                    top: -1.5rem;
                }

                &__info {
                    @apply text-xs;

                    b {
                        @apply block pb-4;
                    }

                    ul {
                        li {
                            @apply font-normal text-blue;
                        }
                    }
                }
            }

            &--legend {
                @apply text-xs text-blue pt-4;
            }
        }

        &__extra {
            @apply grid gap-y-3 relative;

            &--container {
                @apply px-4 py-6 rounded-xl relative;
                border: 2px solid #2A274F;

                &__title {
                    @apply absolute;
                    top: -2rem;

                }

                &__info {
                    @apply text-sm text-blue;

                    a,
                    b,
                    div {
                        @apply font-extrabold;
                    }
                }
            }
        }

        &__legend {
            @apply hidden;
        }
    }
}

@screen lg {
    .plan {
        @apply grid items-center gap-x-4;
        padding: 0rem 7.5% 2rem 7.5%;
        grid-template-columns: auto 1fr;

        &--left {
            grid-template-columns: unset;
            grid-template-rows: 1fr auto;
        }

        &--right {
            @apply gap-x-3 gap-y-2;
            grid-template-columns: 70% auto;

            &__content {
                &--container {
                    @apply px-6 py-4;

                    &__info {
                        @apply text-sm;
                    }
                }

                &--legend {
                    @apply hidden;
                }
            }

            &__extra {
                @apply self-end;

                &--container {
                    @apply px-4 py-6 rounded-xl relative;

                    &:nth-child(3) {
                        @apply pt-10;
                    }
                }
            }

            &__legend {
                @apply block text-sm;
                grid-column: 1 / 3;
            }
        }
    }
}
</style>
  