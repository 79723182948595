import Vue from 'vue'
import Vuex from 'vuex'

import { v4 as uuidv4 } from 'uuid'
import api from '@/services/api'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    initialized: false,
    loading: false,
    website_texts: [],
    website_images: {},
    website_documents: {},
    access_token: null,
    entities: {},
    modal: false,
    baseUrl: 'https://agencia-mercurio.s3.sa-east-1.amazonaws.com/',
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload
    },
    SET_INITIALIZED(state, payload) {
      state.initialized = payload
    },
    SET_WEBSITE_TEXTS(state, payload) {
      state.website_texts = payload
    },
    SET_WEBSITE_IMAGES(state, payload) {
      state.website_images = payload
    },
    SET_WEBSITE_DOCUMENTS(state, payload) {
      state.website_documents = payload
    },
    SET_ACCESS_TOKEN(state, payload) {
      state.access_token = payload
    },
    SET_MODAL(state, payload) {
      state.modal = payload
    },
    SET_ENTITY(state, payload) {
      state.entities[payload.key] = payload.data
    }
  },
  actions: {
    async setInitialized({ commit }, payload) {
      await commit('SET_INITIALIZED', payload)
    },
    async setModal({ commit }, payload) {
      await commit('SET_MODAL', payload)
    },
    async setLoading({ commit }, payload) {
      await commit('SET_LOADING', payload)
    },
    async setWebsiteTexts({ commit }, payload) {
      await commit('SET_WEBSITE_TEXTS', payload)
    },
    async setAccessToken({ commit }) {
      let uuid = Vue.$cookies.get('access_token')

      if (!uuid) {
        uuid = uuidv4()

        await api.setAccessToken(uuid).then(() => {
          Vue.$cookies.set('access_token', uuid, {
            expires: new Date(new Date().getTime() + 3600000)
          })
        })
      }

      await commit('SET_ACCESS_TOKEN', uuid)
    },
    async setAccessEvent({ state, commit }, payload) {
      const accessToken =
        state.access_token ?? Vue.$cookies.get('access_token') ?? uuidv4()
        await commit('SET_ACCESS_TOKEN', accessToken)
      await api.accesEvent(payload.event, payload.value, accessToken)
    },
    async getEntity({ commit }, payload) {
      const { data } = await api.entity(payload)

      await commit('SET_ENTITY', {
        key: payload,
        data
      })
    },
    async setEntity({ commit }, payload) {
      await commit('SET_ENTITY', {
        key: payload.key,
        data: payload.data
      })
    },
    async getWebsiteDocuments({ commit }) {
      const { data } = await api.documents()

      await commit('SET_WEBSITE_DOCUMENTS', data)
    },
    async setWebsiteDocuments({ commit }, payload) {
      await commit('SET_WEBSITE_DOCUMENTS', payload)
    },
    async getWebsiteImages({ commit }) {
      const { data } = await api.images()

      await commit('SET_WEBSITE_IMAGES', data)
    },
    async setWebsiteImages({ commit }, payload) {
      await commit('SET_WEBSITE_IMAGES', payload)
    }
  },
  getters: {
    loading: state => state.loading
  }
})

export default store