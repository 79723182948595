import Vue from 'vue'
import App from './App.vue'

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
Vue.$cookies.config('1h');

import store from './store'
import VueI18n from 'vue-i18n';
import VideoPlayer from 'vue-videojs7'
import VueRoter from 'vue-router'
import VueMeta from 'vue-meta'
import axios from '@/axios';
import VelorioOnline from './views/VelorioOnline'

import M3u8Player from './components/M3u8Player.vue'
import ComposedDiv from './components/ComposedDiv.vue'
import ComposedTitle from './components/ComposedTitle.vue'
import LinedTitle from './components/LinedTitle.vue'
import ResponsiveImage from './components/ResponsiveImage.vue'
import DynamicImage from './components/DynamicImage.vue'
import HomeFrame from './components/HomeFrame.vue'
import Badge from './components/Badge.vue'
import VueSplide from '@splidejs/vue-splide';

import './assets/tailwind.css';
import './assets/base.scss';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

Vue.use(VideoPlayer)
Vue.component('m3u8-player', M3u8Player)
Vue.component('composed-div', ComposedDiv)
Vue.component('composed-title', ComposedTitle)
Vue.component('lined-title', LinedTitle)
Vue.component('responsive-image', ResponsiveImage)
Vue.component('dynamic-image', DynamicImage)
Vue.component('velorio-online', VelorioOnline)
Vue.component('home-frame', HomeFrame)
Vue.component('badge', Badge)
Vue.use(VueI18n);
Vue.use(VueMeta)
Vue.use(VueRoter)
Vue.use(VueSplide)

import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faCircleExclamation, faTimes, faCircleQuestion, faPlay, faBan, faSpinner, faBars } from '@fortawesome/free-solid-svg-icons'



/* add icons to the library */
library.add([
  faCircleExclamation,
  faCircleQuestion,
  faPlay,
  faBan,
  faSpinner,
  faBars,
  faTimes
])

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

const i18n = new VueI18n({
  locale: 'pt', // Set the default locale
  fallbackLocale: 'pt', // Set the fallback locale
  messages: {
    pt: require('../locales/pt.json'),
    // Add more languages as needed
  },
});

Vue.config.productionTip = false

Vue.prototype.$axios = axios;

import router from '../router';


new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
  title: 'Velório Online'
}).$mount('#app')